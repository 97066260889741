import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useDestinations() {
  const t = inject("t");
  const store = useStore();
  const destination = ref({});
  const destinations = ref([]);
  const destinationPrefixes = ref([]);
  const loading = ref(true);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND DESTINATIONS
  const findDestinations = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/routing/prefixes");
      if (response?.data) {
        destinations.value = response.data;
        store.commit("xadmin/SET_XADMIN_DESTINATIONS", response.data);
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  // FIND DESTINATION PREFIXES
  const findDestinationPrefixes = async () => {
    try {
      const response = await api.get(
        "/admin/xpbx/settings/routing/destinations"
      );
      if (response?.data) destinationPrefixes.value = response.data;
    } catch (error) {}
  };

  //   FIND DESTINATION
  const findDestination = async (id) => {
    try {
      const response = await api.getAudio(
        `/admin/xpbx/settings/destination/${id}`
      );

      return response?.data ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  //   CREATE DESTINATION
  const createDestination = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/routing/prefixes`,
        data
      );

      if (response?.data) {
        store.commit("xadmin/ADD_XADMIN_DESTINATIONS", response.data);
        destinations.value = [response.data, ...destinations.value];
        n(
          "xpbx.settings.destinations.notifications.create_destination",
          "success"
        );
      } else {
        n("xpbx.settings.destinations.notifications.error_create_destination");
      }
    } catch (error) {
      n("xpbx.settings.destinations.notifications.error_create_destination");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE DESTINATION
  const updateDestination = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/routing/prefixes/${id}`,
        data
      );

      if (response?.data) {
        const destination = {
          ...response.data,
          id,
        };
        destinations.value = destinations.value.map((i) =>
          i.id === id ? destination : i
        );
        store.commit("xadmin/UPDATE_XADMIN_DESTINATIONS", {
          data: destination,
          id,
        });
        n(
          "xpbx.settings.destinations.notifications.edit_destination",
          "success"
        );
      } else {
        n(
          "xpbx.settings.destinations.notifications.error_edit_destination",
          "success"
        );
      }
    } catch (error) {
      n(
        "xpbx.settings.destinations.notifications.error_edit_destination",
        "success"
      );
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE DESTINATION
  const deleteDestination = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/routing/prefixes/${id}`
      );

      if (response.status === 200) {
        n(
          "xpbx.settings.destinations.notifications.delete_destination",
          "success"
        );
        store.commit("xadmin/DELETE_XADMIN_DESTINATIONS", id);
        destinations.value = destinations.value.filter((i) => i.id !== id);
      } else {
        n("xpbx.settings.destinations.notifications.error_delete_destination");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.destinations.notifications.error_delete_destination");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    loading,
    destination,
    destinations,
    destinationPrefixes,

    findDestinations,
    findDestination,
    createDestination,
    updateDestination,
    deleteDestination,
    findDestinationPrefixes,
  };
}
