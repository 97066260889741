import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const {
  checkRequired,
  checkValueNotIn,
  checkEmail,
  checkIsNumber,
  errors,
  clearErrors,
  isValidUrl,
} = validator();

export function validateCreateNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("country", data);
  checkRequired("type", data);

  return hasErrors.value ? false : true;
}

export function validateCreateExtensionNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("description", data);
  checkRequired("extension", data);
  checkRequired("is_active", data);

  return hasErrors.value ? false : true;
}

export function validateUpdateNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("description", data);

  return hasErrors.value ? false : true;
}

export function validateCreateVoicemail(data) {
  clearErrors();

  data = unref(data);

  checkRequired("description", data);
  checkRequired("mail_to", data);
  checkEmail("mail_to", data);
  checkRequired("mail_from", data);
  checkEmail("mail_from", data);
  checkRequired("mail_body", data);
  checkRequired("mail_subject", data);

  return hasErrors.value ? false : true;
}

export function validateCreateCarrier(data) {
  clearErrors();

  data = unref(data);

  checkRequired("carrier_name", data);

  return hasErrors.value ? false : true;
}

export function validateCreateDestination(data) {
  clearErrors();

  data = unref(data);

  checkRequired("prefix_name", data);
  checkRequired("prefix", data);
  checkRequired("country_id", data);

  return hasErrors.value ? false : true;
}

export function validateCreateRouting(data) {
  clearErrors();

  data = unref(data);

  checkRequired("a_regexp", data);
  checkRequired("b_regexp", data);
  checkRequired("flow_id", data);
  checkRequired("priority", data);
  checkRequired("timeslot_id", data);
  checkRequired("application_id", data);

  return hasErrors.value ? false : true;
}
