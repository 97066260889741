<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.settings.destinations.heading')"
        :showButton="false"
      />
      <!-- Extensions table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="destinations"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} recorder"
          selectionMode="single"
          dataKey="id"
          v-if="!loading"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :globalFilterFields="['prefix_name', 'prefix']"
        >
          <template #empty>
            {{ $t("xpbx.settings.destinations.fields.empty_table") }}</template
          >
          <template #header>
            <section>
              <div
                class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
              >
                <div>
                  <Button
                    :label="$t('xpbx.button.new')"
                    class="mr-2 add-record-button"
                    @click="openNew"
                  />
                </div>

                <div class="flex gap-2 items-center ml-auto">
                  <InputText
                    class="search-input"
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                  <Export
                    :dt="dt"
                    :tableData="destinations"
                    :columns="carriersHeaders"
                  />
                </div>
              </div>
              <div class="flex justify-end">
                <AlphaBetFilter @activeLetter="filterDestinations" />
              </div>
            </section>
          </template>
          <Column
            sortable
            field="prefix_name"
            style="width: 20%"
            :header="$t('xpbx.settings.destinations.fields.prefix_name')"
          >
            <template #body="{ data }">
              <div>
                {{ data?.prefix_name }}
              </div>
              <TableActions
                :showIsActive="false"
                :data="data"
                :id="data.id"
                @edit="editHandle"
                @delete="deleteSelectedRecord"
              />
            </template>
          </Column>

          <Column
            sortable
            style="width: 20%"
            field="prefix"
            :header="$t('xpbx.settings.destinations.fields.prefix')"
          />

          <Column
            sortable
            style="width: 20%"
            field="country_id"
            :header="$t('xpbx.settings.destinations.fields.country')"
          >
            <template #body="{ data }">
              <div>
                {{ getCountryName(data?.country_id) }}
              </div>
            </template>
          </Column>

          <Column
            sortable
            style="width: 20%"
            field="is_mobile"
            :header="$t('xpbx.settings.destinations.fields.is_mobile')"
          >
            <template #body="{ data }">
              <div>
                {{
                  data.is_mobile
                    ? $t("xpbx.settings.destinations.fields.yes")
                    : $t("xpbx.settings.destinations.fields.no")
                }}
              </div>
            </template>
          </Column>

          <Column
            sortable
            style="width: 20%"
            field="is_itfs"
            :header="$t('xpbx.settings.destinations.fields.is_itfs')"
          >
            <template #body="{ data }">
              <div>
                {{
                  data.is_itfs
                    ? $t("xpbx.settings.destinations.fields.yes")
                    : $t("xpbx.settings.destinations.fields.no")
                }}
              </div>
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("xpbx.settings.destinations.notifications.confirm_delete", {
              delete: `destination ${selectedRecord?.prefix_name || ""}`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create Prefix -->
      <Dialog
        v-model:visible="recordDialog"
        :style="{ width: '800px' }"
        :header="$t('xpbx.settings.destinations.dialogs.create_destination')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Prefix name -->
        <div class="field mb-1 select-autocomplete">
          <label for="description">{{
            $t("xpbx.settings.destinations.labels.prefix_name")
          }}</label>
          <SelectWithSearch
            v-model="destination.prefix_name"
            :options="prefixOptions"
            :bordered="true"
            name="prefix_name"
            id="prefix_name"
            :errors="errors.prefix_name"
            :footerLabel="$t('xpbx.settings.destinations.hints.prefix_name')"
          />
        </div>
        <QueueHint
          :title="$t('xpbx.settings.destinations.hints.prefix_name')"
        />

        <!-- Prefix -->
        <FormInput
          id="prefix"
          :error="errors?.prefix?.[0]"
          v-model:modelValue="destination.prefix"
          :label="$t('xpbx.settings.destinations.labels.prefix')"
          :hint="$t('xpbx.settings.destinations.hints.prefix')"
        />

        <!-- Country -->
        <div class="field mb-1 select-autocomplete">
          <label for="description">{{
            $t("xpbx.settings.destinations.labels.country")
          }}</label>
          <SelectWithSearch
            v-model="destination.country_id"
            :options="countryOptions"
            :bordered="true"
            name="country"
            id="country"
            :errors="errors.country_id"
            :footerLabel="$t('xpbx.settings.destinations.hints.country')"
          />
        </div>
        <QueueHint :title="$t('xpbx.settings.destinations.hints.country')" />

        <!-- Is Mobile -->
        <InputSwitch
          v-model:modelValue="destination.is_mobile"
          :label="$t('xpbx.settings.destinations.labels.is_mobile')"
          :hint="$t('xpbx.settings.destinations.hints.is_mobile')"
        />

        <!-- Is itfs -->
        <InputSwitch
          v-model:modelValue="destination.is_itfs"
          :label="$t('xpbx.settings.destinations.labels.is_itfs')"
          :hint="$t('xpbx.settings.destinations.hints.is_itfs')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :saveAddMoreText="isEdit ? '' : $t('xpbx.button.save_add_more')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @saveMore="saveAndAddMore"
            @cancel="recordDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, watch, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import useDestinations from "@/modules/xpbx/composables/useDestinations";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Timezones from "@/shared/Timezones";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateCreateDestination } from "@/composables/auth/createNumberValidations";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import { carriersHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import AlphaBetFilter from "@/modules/xpbx/pages/settings/components/AlphaBetFilter/AlphaBetFilter.vue";

export default {
  name: "Destinations",

  components: {
    PageHeader,
    DataTable,
    Column,
    Dropdown,
    Loader,
    Button,
    InputText,
    Dialog,
    Timezones,
    Export,
    FormInput,
    DialogButtons,
    InputSwitch,
    TableActions,
    QueueHint,
    AlphaBetFilter,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { findCarriers } = useCarriers();
    const store = useStore();
    const {
      loading,
      destination,
      destinationPrefixes,
      findDestinations,
      createDestination,
      updateDestination,
      deleteDestination,
      findDestinationPrefixes,
    } = useDestinations();
    // Datatable
    const dt = ref();
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 350);
    const isEdit = ref(false);
    const recordDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const countries = computed(() => store.getters.countries);
    const destinations = computed(
      () => store.state.xadmin.filteredDestinations
    );
    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    const prefixOptions = computed(() => {
      return destinationPrefixes.value.map((i) => {
        return {
          name: i,
          value: i,
        };
      });
    });

    const timezones = computed(() => store.getters.timezoneOptions);

    const resetDestination = () => {
      destination.value = {
        prefix_name: "",
        prefix: "",
        country_id: "",
        is_mobile: false,
        is_itfs: false,
      };
      submitted.value = false;
      clearErrors();
    };

    const openNew = () => {
      isEdit.value = false;
      recordDialog.value = true;
    };

    const closeDialog = (value) => {
      recordDialog.value = value;
    };

    const createCallback = () => {
      submitted.value = false;
      recordDialog.value = false;
    };

    const editHandle = (data) => {
      isEdit.value = false;
      selectedRecord.value = data;
      const country = countries.value.find((x) => x.id === data.country_id);

      const form = {
        ...data,
        country_id: country?.country_name || "",
        is_itfs: data.is_itfs === 1 ? true : false,
        is_mobile: data.is_mobile === 1 ? true : false,
      };

      destination.value = form;
      isEdit.value = true;
      recordDialog.value = true;
    };

    const updateRecord = async (data) => {
      submitted.value = true;

      const formData = getFormData(destination.value);

      try {
        await updateDestination(formData, data.id);
        createCallback();
      } catch (error) {
        createCallback();
      }
    };

    const getFormData = (data) => {
      let countryId = data.country_id;

      if (typeof data.country_id === "string") {
        const country = countries.value.find(
          (x) => x.country_name === destination.value.country_id
        );

        countryId = country?.id || null;
      }

      return {
        ...data,
        country_id: countryId,
        is_itfs: data.is_itfs ? 1 : 0,
        is_mobile: data.is_mobile ? 1 : 0,
      };
    };

    const createRecord = async () => {
      submitted.value = true;
      const formData = getFormData(destination.value);

      try {
        await createDestination(formData);
        createCallback();
      } catch (error) {
        createCallback();
      }
    };

    const create = async () => {
      const isValid = validateCreateDestination(destination.value);

      if (!isValid) return;
      if (isEdit.value) {
        await updateRecord(destination.value);
      } else {
        await createRecord(destination.value);
      }
    };

    const saveAndAddMore = async () => {
      const isValid = validateCreateDestination(destination.value);

      if (!isValid) return;

      const formData = getFormData(destination.value);

      await createDestination(formData);
      resetDestination();
    };

    const filterDestinations = (letter) => {
      store.commit("xadmin/FILTER_XADMIN_DESTINATIONS", letter);
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const getCountryName = (id) => {
      const country = countries.value.find((x) => x.id === id);

      return country?.country_name || "";
    };

    const deleteSelectedRecords = async () => {
      if (!selectedRecord.value?.id) return;

      await deleteDestination(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    watch(recordDialog, (value) => {
      if (!value) resetDestination();
    });

    onMounted(async () => {
      await findCarriers();
      await findDestinationPrefixes();
      await findDestinations();
    });

    return {
      dt,
      isEdit,
      loading,
      countries,
      countryOptions,
      prefixOptions,
      destination,
      destinations,
      carriersHeaders,
      errors,
      filters,
      submitted,
      timezones,
      recordDialog,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      scrollHeight,
      // Methods
      create,
      openNew,
      editHandle,
      closeDialog,
      getFormData,
      saveAndAddMore,
      getCountryName,
      filterDestinations,
      findDestinations,
      resetDestination,
      deleteDestination,
      deleteSelectedRecord,
      deleteSelectedRecords,
      findDestinationPrefixes,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>